<template>
  <div v-if="eventIsLoaded">
    <v-form v-if="eventHasAppointments" class="form" v-model="valid">
      <v-container>
        <h2>{{ $t('meetings.configuration.configure') }}</h2>

        <v-row>
          <v-radio-group
            id="meeting-duration"
            :value="getSlotDuration"
            :label="$t('meetings.configuration.duration')"
            @change="slotDurationChanged"
            row
          >
            <v-radio :label="`15 mn`" :value="15" />
            <v-radio :label="`30 mn`" :value="30" />
          </v-radio-group>
        </v-row>

        <h2>{{ $t('meetings.configuration.availabilities') }}</h2>
        <v-row
          v-for="day in getDaysKeys"
          v-bind:key="`availability-for-${day}`"
          class="availability"
        >
          <v-col md="3" sm="8" cols="8" order-md="1" class="availability__date">
            <span>{{ readableDate(day) }}</span>
          </v-col>
          <v-col
            md="4"
            sm="12"
            cols="12"
            order-md="2"
            order="3"
            order-sm="3"
            class="availability__slot"
          >
            <div v-if="slotsForDay(day).length > 0">
              <div
                v-for="slot in slotsForDay(day)"
                v-bind:key="`slot-${slot.id}`"
                class="availability-slot"
              >
                <v-text-field
                  :value="slot.start"
                  @change="startSlotChanged($event, slot.id, day)"
                  :label="$t('meetings.configuration.start')"
                  :hint="$t('meetings.configuration.slot-start-hint')"
                  :rules="[rules.startTimeRequired(), rules.startTimeValid()]"
                  :disabled="slot.saved"
                />
                <span class="availability-slot__separator">-</span>
                <v-text-field
                  :value="slot.end"
                  @change="endSlotChanged($event, slot.id, day)"
                  :label="$t('meetings.configuration.end')"
                  :hint="$t('meetings.configuration.slot-end-hint')"
                  :rules="[
                    rules.endTimeRequired(),
                    rules.endTimeValid(),
                    rules.endTimeAfterStart(slot.start),
                  ]"
                  :disabled="slot.saved"
                />
                <v-btn icon @click="removeSlotClicked(slot, day)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
            <div v-else class="availability-slot__unavailable">
              {{ $t('meetings.configuration.unavailable') }}
            </div>
          </v-col>
          <v-col
            md="auto"
            sm="4"
            cols="4"
            order-md="3"
            order="2"
            order-sm="2"
            class="availability__more"
          >
            <v-btn icon @click="addSlotClicked(day)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-btn color="primary" depressed @click="save" :disabled="!valid || !hasUnsavedSlot">
          {{ $t('globals.save') }}
        </v-btn>

        <overlay-loading :size="64" v-if="isLoading" />
      </v-container>
    </v-form>

    <div v-else class="ma-8">
      <p>{{ $t('meetings.configuration.event-has-no-appointments') }}</p>
      <v-btn color="primary" text @click="back">{{ $t('globals.back') }} </v-btn>
    </div>
  </div>

  <loading v-else :size="64" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import { DATE_MED_WITH_WEEKDAY } from 'luxon/src/impl/formats';

import DateUtil from '@/helpers/date/date.helper';

import OverlayLoading from '@/components/loading/OverlayLoading.vue';
import Loading from '@/components/loading/Loading.vue';

import {
  ADD_SLOT,
  AVAILABILITY_MODULE,
  LOAD_USER_AVAILABILITY,
  REMOVE_SLOT,
  SAVE,
  SET_END_SLOT,
  SET_SLOT_DURATION,
  SET_START_SLOT,
} from '@/stores/umanize-app/actions/availability/availability.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';

export default {
  name: 'MeetingConfiguration',
  components: {
    Loading,
    OverlayLoading,
  },
  data() {
    return {
      valid: false,
    };
  },
  methods: {
    ...mapActions(AVAILABILITY_MODULE, [
      LOAD_USER_AVAILABILITY,
      SET_SLOT_DURATION,
      ADD_SLOT,
      REMOVE_SLOT,
      SET_START_SLOT,
      SET_END_SLOT,
      SAVE,
    ]),
    async slotDurationChanged(value) {
      await this[SET_SLOT_DURATION](value);
    },
    async addSlotClicked(day) {
      await this[ADD_SLOT](day);
    },
    async removeSlotClicked(slot, day) {
      await this[REMOVE_SLOT]({
        slot,
        day,
      });
    },
    async startSlotChanged(value, id, day) {
      await this[SET_START_SLOT]({
        value,
        id,
        day,
      });
    },
    async endSlotChanged(value, id, day) {
      await this[SET_END_SLOT]({
        value,
        id,
        day,
      });
    },
    async save() {
      await this[SAVE]();
    },
    back() {
      this.$router.back();
    },
  },
  computed: {
    ...mapGetters(APP_EVENT_MODULE, ['eventIsLoaded']),
    ...mapGetters(APP_EVENT_MODULE, { appEvent: 'event' }),
    ...mapGetters(AVAILABILITY_MODULE, [
      'getSlotDuration',
      'getDaysKeys',
      'getSlotsForDay',
      'hasUnsavedSlot',
      'isLoading',
    ]),
    rules() {
      return {
        startTimeRequired() {
          return (v) => !!v || this.$t('meetings.configuration.errors.start-time-required');
        },
        startTimeValid() {
          return (v) =>
            (!!v && DateUtil.isValidTimeFormat24h(v)) ||
            this.$t('meetings.configuration.errors.time-format');
        },
        endTimeRequired() {
          return (v) => !!v || this.$t('meetings.configuration.errors.end-time-required');
        },
        endTimeValid() {
          return (v) =>
            (!!v && DateUtil.isValidTimeFormat24h(v)) ||
            this.$t('meetings.configuration.errors.time-format');
        },
        endTimeAfterStart(start) {
          return (v) => {
            if (!v || !start) {
              return false;
            }

            const startTime = DateUtil.dateTimeFromTimeFormat24h(start);
            const endTime = DateUtil.dateTimeFromTimeFormat24h(v);

            return (
              endTime.diff(startTime).toMillis() > 0 ||
              this.$t('meetings.configuration.errors.end-time-after-start')
            );
          };
        },
      };
    },
    eventHasAppointments() {
      return this.appEvent.options.appointments;
    },
    readableDate() {
      return (date) =>
        DateTime.fromFormat(date, DateUtil.ISO_DATE_FORMAT).toLocaleString(DATE_MED_WITH_WEEKDAY);
    },
    slotsForDay() {
      return (day) => this.getSlotsForDay(day);
    },
  },
  mounted() {
    if (this.eventHasAppointments) {
      this[LOAD_USER_AVAILABILITY]();
    }
  },
};
</script>

<style lang="scss">
@import '../../styles/core/_variables';

.availability {
  align-items: baseline;
}

.availability-slot {
  display: flex;
  align-items: baseline;
}

.availability-slot__unavailable {
  align-self: center;
}

.availability-slot__separator {
  align-self: center;
  margin: 0 1em;
}

.availability__more {
  text-align: right;
}

@include breakpoint(small) {
  .availability__more {
    text-align: initial;
  }
}
</style>
