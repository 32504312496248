<template>
  <v-sheet dark v-if="eventIsLoaded && !isLoading">
    <v-container v-if="appointmentExists(meetingId)">
      <div class="room">
        <iframe
          v-if="canAccessAppointment()"
          style="width: 100%; border: none"
          class="room__iframe"
          :src="getWherebyUrl"
          allow="camera; microphone; fullscreen; speaker; display-capture"
        />
        <div v-else>
          <v-alert type="info">
            {{ $t('meetings.detail.start-soon', bookingWith()) }}
          </v-alert>
        </div>
      </div>
    </v-container>
    <v-container v-else>
      <div class="room">
        <div>
          <v-alert type="error">
            <p>{{ $t('meetings.detail.404') }}</p>
            <router-link :to="{ name: 'Lobby', params: { eventId: eventId } }">{{
              $t('menu.lobby')
            }}</router-link>
          </v-alert>
        </div>
      </div>
    </v-container>
  </v-sheet>

  <loading v-else :size="64" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import {
  APPOINTMENT_MODULE,
  GET_APPOINTMENTS,
} from '@/stores/umanize-app/actions/appointment/appointment.action';
import { whereByIframeSrcBuilder } from '@/helpers/whereby/whereby.helper';
import {
  bookingWith,
  canAccessAppointment,
} from '@/models/users-appointments/user-event-appointment';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import Loading from '../../components/loading/Loading.vue';

export default {
  name: 'MeetingDetail',
  components: {
    Loading,
  },
  data() {
    return {
      now: DateTime.now(),
      timer: null,
    };
  },
  computed: {
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(APPOINTMENT_MODULE, ['isLoading', 'getAppointmentById', 'appointmentExists']),
    ...mapGetters(APP_EVENT_MODULE, ['eventIsLoaded']),
    getAppointment() {
      return this.getAppointmentById(this.meetingId);
    },
    eventId() {
      return this.$route.params.eventId;
    },
    meetingId() {
      return this.$route.params.meetingId;
    },
    bookingWith() {
      return () => bookingWith(this.getAppointment, this.loggedInUser);
    },
    getWherebyUrl() {
      const appointment = this.getAppointment;

      const wherebyUrl = appointment?.wherebyHostUrl || appointment?.wherebyUrl;
      return whereByIframeSrcBuilder(wherebyUrl, {
        embed: true,
        background: 'off',
        chat: 'on',
        screenShare: 'on',
        recording: 'on',
        displayName: `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`,
      });
    },
    canAccessAppointment() {
      return () => {
        const test = canAccessAppointment(this.now)(this.getAppointment);
        if (test) {
          clearInterval(this.timer);
        }
        return test;
      };
    },
  },
  methods: {
    ...mapActions(APPOINTMENT_MODULE, [GET_APPOINTMENTS]),
  },
  async mounted() {
    await this[GET_APPOINTMENTS]({ eventId: this.eventId });
  },
  created() {
    this.timer = setInterval(() => {
      this.now = DateTime.now();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.room {
  display: flex;
  justify-content: center;
  min-height: 80vh;

  &__iframe {
    width: 90%;
  }
}

.theme--dark.v-sheet a {
  color: #ffffff;
}
</style>
