<template>
  <v-container v-if="userProfile && !isLoading">
    <div>
      <h2>{{ $t('meetings.booking.book-with', userProfile) }}</h2>
      <h3>{{ $t('meetings.booking.select-slot') }}</h3>

      <div class="d-flex flex-column flex-lg-row">
        <v-date-picker
          :allowed-dates="allowedDates"
          :value="selectedDate"
          @change="setSelectedDate"
          width="350"
        />
        <div class="d-flex flex-grow-1">
          <div v-if="hasAvailableAppointmentSlots" class="d-flex flex-column flex-grow-1 ml-4">
            <v-btn
              v-for="(slot, index) in availableAppointmentSlotsForDay(selectedDate)"
              :key="`availableAppointmentSlotsFor-${slot.isoDate}-${index}`"
              outlined
              color="blue"
              class="my-2"
              @click="selectSlot(slot)"
              >{{ formatLocalTime(slot.starting) }} - {{ formatLocalTime(slot.ending) }}
            </v-btn>
          </div>
          <div v-else>{{ $t('meetings.booking.no-slot') }}</div>
        </div>
      </div>
    </div>

    <v-dialog
      content-class="v-dialog--overlay"
      v-if="selectedSlot"
      v-model="showDialogStep2"
      persistent
      width="600"
    >
      <v-card :loading="isSaving">
        <v-card-title>{{ $t('meetings.booking.modal.title', userProfile) }}</v-card-title>
        <v-card-text>
          <v-form :disabled="isSaving">
            <p>
              {{
                $t('meetings.booking.modal.meeting-time', {
                  day: formatLocalDate(selectedSlot.starting),
                  start: formatLocalTime(selectedSlot.starting),
                  end: formatLocalTime(selectedSlot.ending),
                })
              }}
            </p>
            <p>
              {{ $t('meetings.booking.modal.description') }}
            </p>
            <v-textarea :value="description" @change="setDescription" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="book" :disabled="isSaving">
            {{ $t('meetings.booking.modal.submit') }}
          </v-btn>
          <v-btn color="secondary darken-1" text @click="cancelBooking" :disabled="isSaving">
            {{ $t('globals.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="v-dialog--overlay"
      v-if="selectedSlot"
      v-model="showDialogConfirmation"
      persistent
      width="600"
    >
      <v-card>
        <v-card-title>
          {{ $t('meetings.booking.modal.confirmation-title') }}
        </v-card-title>
        <v-card-text>
          {{ $t('meetings.booking.modal.confirmation', userProfile) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="back">
            {{ $t('globals.back') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

  <loading v-else :size="64" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import Loading from '@/components/loading/Loading.vue';
import {
  APPOINTMENT_MODULE,
  GET_APPOINTMENTS_SLOT,
  SAVE_APPOINTMENT,
} from '@/stores/umanize-app/actions/appointment/appointment.action';
import {
  GET_PUBLIC_PROFILE_BY_ID,
  PUBLIC_PROFILE_MODULE,
} from '@/stores/umanize-app/actions/public-profile/public-profile.actions';

export default {
  name: 'MeetingBooking',
  components: {
    Loading,
  },
  data() {
    return {
      showDialogStep2: false,
      showDialogConfirmation: false,
      selectedDate: undefined,
      selectedSlot: undefined,
      description: undefined,
    };
  },
  methods: {
    ...mapActions(PUBLIC_PROFILE_MODULE, [GET_PUBLIC_PROFILE_BY_ID]),
    ...mapActions(APPOINTMENT_MODULE, [GET_APPOINTMENTS_SLOT, SAVE_APPOINTMENT]),
    setSelectedDate(date) {
      this.selectedDate = date;
    },
    setDescription(description) {
      this.description = description;
    },
    selectSlot(slot) {
      this.showDialogStep2 = true;
      this.selectedSlot = slot;
    },
    async book() {
      await this[SAVE_APPOINTMENT]({
        eventId: this.eventId,
        description: this.description,
        slotUserId: this.userId,
        starting: this.selectedSlot.starting,
        userEventAvailabilityId: this.selectedSlot.userEventAvailabilityId,
      });
      this.showDialogStep2 = false;
      this.showDialogConfirmation = true;
    },
    cancelBooking() {
      this.showDialogStep2 = false;
      this.selectedSlot = undefined;
    },
    back() {
      this.$router.back();
      this.selectedSlot = undefined;
    },
  },
  computed: {
    ...mapGetters(PUBLIC_PROFILE_MODULE, ['publicProfileByUserId']),
    ...mapGetters(APPOINTMENT_MODULE, [
      'hasAvailableAppointmentSlots',
      'getAvailableDays',
      'getAvailableAppointmentSlotsForDay',
      'getFirstAvailableDay',
      'isSaving',
      'isLoading',
    ]),
    userId() {
      return this.$route.params.userId;
    },
    eventId() {
      return this.$route.params.eventId;
    },
    userProfile() {
      return this.publicProfileByUserId(this.userId);
    },
    allowedDates() {
      return (date) => this.getAvailableDays.indexOf(date) !== -1;
    },
    availableAppointmentSlotsForDay() {
      return (date) => this.getAvailableAppointmentSlotsForDay(date);
    },
    formatLocalTime() {
      return (dateTime) => this.$d(DateTime.fromISO(dateTime).toJSDate(), 'shortTime');
    },
    formatLocalDate() {
      return (dateTime) => this.$d(DateTime.fromISO(dateTime).toJSDate(), 'longDate');
    },
  },
  async mounted() {
    await Promise.all([
      this[GET_PUBLIC_PROFILE_BY_ID](this.userId),
      this[GET_APPOINTMENTS_SLOT]({
        eventId: this.eventId,
        userId: this.userId,
      }),
    ]);
    this.selectedDate = this.getFirstAvailableDay;
  },
};
</script>

<style lang="scss"></style>
