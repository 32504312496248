<template>
  <div class="meetings">
    <loading v-if="isLoading && !eventIsLoaded" :size="64" />

    <v-container v-else :class="{ 'menu-open': displayMenu }">
      <v-row class="meetings__header">
        <v-col>
          <h2 class="header__title">{{ $t('meetings.list.title') }}</h2>

          <div class="header__actions">
            <v-btn
              color="primary"
              class="mr-md-4 mb-md-0 mb-2"
              depressed
              @click="togglePastMeetings"
              v-if="getPreviousAppointments.length"
            >
              {{ showPastMeetings ? $t('meetings.list.hidePast') : $t('meetings.list.showPast') }}
            </v-btn>

            <v-btn
              v-if="eventHasAppointments"
              class="actions__config"
              color="primaryFont"
              depressed
              outlined
              :to="{ name: 'MeetingConfiguration', params: { eventId } }"
            >
              {{ $t('meetings.list.configure') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <template v-if="!showPastMeetings">
        <v-row v-if="getNextAppointments.length">
          <v-col>
            <template v-for="appointment in sortedAppointments(getNextAppointments)">
              <div
                v-if="isDateVisible(appointment)"
                :key="`appointment-${appointment.id}-1`"
                class="mb-4 d-flex align-center appointments__date"
              >
                <v-divider />
                <span class="mx-4 date">{{ formattedDate(appointment.starting) }}</span>
                <v-divider />
              </div>

              <v-card
                :key="`appointment-${appointment.id}`"
                class="mb-8 d-flex align-center pa-4 appointments__appointment"
                @click="openUserDialog(appointment)"
              >
                <user-avatar
                  :avatar="bookingWith(appointment).avatar"
                  :no-border="true"
                  :size="80"
                />

                <div
                  class="pl-4 d-flex justify-space-between align-md-end flex-md-row flex-column grow"
                >
                  <div>
                    <v-card-title class="pa-0">
                      <i18n path="meetings.list.meetingWith">
                        <template v-slot:name>
                          <strong>
                            {{ bookingWith(appointment).firstName }}
                            {{ bookingWith(appointment).lastName }}
                          </strong>
                        </template>
                      </i18n>
                    </v-card-title>

                    <v-card-text class="pa-0">
                      <p class="font-weight-bold">
                        {{ formatDateTime(appointment) }}
                      </p>

                      <p class="mb-0">
                        {{ appointment.description }}
                      </p>
                    </v-card-text>
                  </div>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :disabled="!canAccessAppointment(appointment)"
                      color="primary"
                      :to="{
                        name: 'MeetingDetail',
                        params: { eventId, meetingId: appointment.id },
                      }"
                      >{{ $t('meetings.list.participate') }}
                    </v-btn>

                    <v-btn color="secondary" text @click="confirmCancelAppointment(appointment)">
                      {{ $t('meetings.list.cancel') }}
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </template>
          </v-col>
        </v-row>

        <div v-else>
          <p class="my-8">{{ $t('meetings.list.noMeeting') }}</p>
        </div>
      </template>

      <v-row v-if="showPastMeetings" class="meetings__appointments">
        <v-col>
          <template v-for="appointment in sortedAppointments(getPreviousAppointments)">
            <div
              v-if="isDateVisible(appointment)"
              :key="`appointment-${appointment.id}-1`"
              class="mb-4 d-flex align-center appointments__date"
            >
              <v-divider />
              <span class="mx-4 date">{{ formattedDate(appointment.starting) }}</span>
              <v-divider />
            </div>

            <v-card
              :key="`appointment-${appointment.id}`"
              class="mb-8 d-flex align-center pa-4 appointments__appointment"
              @click="openUserDialog(appointment)"
            >
              <user-avatar :avatar="bookingWith(appointment).avatar" :no-border="true" :size="80" />

              <div class="pl-4">
                <v-card-title class="pa-0">
                  <i18n path="meetings.list.meetingWith">
                    <template v-slot:name>
                      <strong>
                        {{ bookingWith(appointment).firstName }}
                        {{ bookingWith(appointment).lastName }}
                      </strong>
                    </template>
                  </i18n>
                </v-card-title>

                <v-card-text class="pa-0">
                  <p class="font-weight-bold">
                    {{ formatDateTime(appointment) }}
                  </p>

                  <p class="mb-0">
                    {{ appointment.description }}
                  </p>
                </v-card-text>
              </div>
            </v-card>
          </template>
        </v-col>
      </v-row>

      <dialog-confirmation
        :v-if="appointmentsToCancel"
        :is-loading="isLoading"
        :visible="showConfirmation"
        :title="$t('meetings.deleteConfirmationDialog.title')"
        :content="
          $t('meetings.deleteConfirmationDialog.content', bookingWith(appointmentsToCancel))
        "
        @cancel="confirmationDialogCancel"
        @confirm="confirmationDialogConfirm"
      />
    </v-container>

    <v-dialog content-class="v-dialog--overlay" v-model="showOnBoarding" width="500" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('meetings.wizard.title') }}</span>
        </v-card-title>

        <v-card-text>
          {{ $t('meetings.wizard.content') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="wizardConfirm">{{ $t('globals.yes') }} </v-btn>
          <v-btn color="secondary" text @click="wizardCancel">{{ $t('globals.no') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <user-info
      v-if="displayMenu"
      :user="selectedUser"
      :menu-open="displayMenu"
      :event-id="eventId"
      :whereby-url="selectedUrl"
      @change="toggleDisplayMenu"
      @close-menu="closeUserDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import cloneDeep from 'lodash.clonedeep';
import i18n from '@/i18n';

import { toMeetingConfiguration } from '@/navigation';
import {
  bookingWith,
  canAccessAppointment,
  formatAppointmentDate,
} from '@/models/users-appointments/user-event-appointment';
import DateUtil from '@/helpers/date/date.helper';

import {
  APP_EVENT_MODULE,
  UPDATE_WANTS_APPOINTMENT,
} from '@/stores/umanize-app/actions/event/app-event.actions';
import {
  APPOINTMENT_MODULE,
  DELETE_APPOINTMENT,
  GET_APPOINTMENTS,
} from '@/stores/umanize-app/actions/appointment/appointment.action';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { FETCH_EVENT_USERS } from '@/stores/agnostic/actions/event/agnostic-event.actions';

import Loading from '@/components/loading/Loading.vue';
import UserAvatar from '@/components/user-avatar/UserAvatar.vue';
import UserInfo from '@/components/user-info/UserInfo.vue';
import DialogConfirmation from '../DialogConfirmation.vue';

export default {
  name: 'Meetings',
  components: {
    Loading,
    DialogConfirmation,
    UserAvatar,
    UserInfo,
  },
  data() {
    return {
      now: DateTime.now(),
      timer: null,
      showConfirmation: false,
      appointmentsToCancel: null,
      showPastMeetings: false,
      selectedUser: null,
      selectedUrl: null,
      displayMenu: false,
    };
  },
  computed: {
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(APPOINTMENT_MODULE, [
      'getUserHasAppointment',
      'getNextAppointments',
      'getPreviousAppointments',
      'hasAppointments',
      'isLoading',
    ]),
    ...mapGetters(APP_EVENT_MODULE, ['eventIsLoaded', 'eventUserFilterId']),
    ...mapGetters(APP_EVENT_MODULE, { appEvent: 'event' }),
    eventId() {
      return this.$route.params.eventId;
    },
    formatDateTime() {
      return (appointment) => formatAppointmentDate(i18n)(appointment);
    },
    eventHasAppointments() {
      return this.appEvent.options.appointments;
    },
    bookingWith() {
      return (appointment) => bookingWith(appointment, this.loggedInUser);
    },
    canAccessAppointment() {
      return (appointment) => canAccessAppointment(this.now)(appointment);
    },
    showOnBoarding() {
      const { ticket } = this.appEvent;
      return ticket?.wantsAppointments === null;
    },
    sortedAppointments() {
      return (appointments) => {
        const dates = [];
        let appointmentsCopy = cloneDeep(appointments);
        appointmentsCopy = appointmentsCopy.sort(
          (a, b) => (a.starting || '').localeCompare(b.starting || '') * -1,
        );

        // eslint-disable-next-line no-restricted-syntax
        for (const a of appointmentsCopy) {
          a.show = false;

          if (!dates.includes(this.formattedDate(a.starting))) {
            dates.push(this.formattedDate(a.starting));
            a.show = true;
          }
        }

        return appointmentsCopy;
      };
    },
    isDateVisible() {
      return (appointment) => appointment.show;
    },
  },
  async mounted() {
    await this[GET_APPOINTMENTS]({ eventId: this.eventId });
    await this[FETCH_EVENT_USERS]({ id: this.eventId, isActive: true });
  },
  created() {
    this.timer = setInterval(() => {
      this.now = DateTime.now();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    ...mapActions(APPOINTMENT_MODULE, [GET_APPOINTMENTS, DELETE_APPOINTMENT]),
    ...mapActions(APP_EVENT_MODULE, [UPDATE_WANTS_APPOINTMENT, FETCH_EVENT_USERS]),
    confirmCancelAppointment(appointment) {
      this.appointmentsToCancel = appointment;
      this.showConfirmation = true;
    },
    confirmationDialogCancel() {
      this.appointmentsToCancel = null;
      this.showConfirmation = false;
    },
    async confirmationDialogConfirm() {
      await this.cancelAppointment(this.appointmentsToCancel);
      this.appointmentsToCancel = null;
      this.showConfirmation = false;
    },
    async cancelAppointment(appointment) {
      await this[DELETE_APPOINTMENT]({
        eventId: this.eventId,
        appointmentId: appointment.id,
      });
    },
    async wizardCancel() {
      await this[UPDATE_WANTS_APPOINTMENT]({
        eventId: this.eventId,
        wantsAppointments: false,
      });
    },
    async wizardConfirm() {
      await this[UPDATE_WANTS_APPOINTMENT]({
        eventId: this.eventId,
        wantsAppointments: true,
      });
      toMeetingConfiguration(this.$router)({ eventId: this.eventId });
    },
    togglePastMeetings() {
      this.showPastMeetings = !this.showPastMeetings;
    },
    formattedDate(date) {
      return DateUtil.getFormattedDate(date, this.$root.$i18n.locale);
    },
    openUserDialog(appointment) {
      this.selectedUser = bookingWith(appointment, this.loggedInUser);
      this.selectedUrl = appointment.wherebyUrl;
      this.displayMenu = true;
    },
    closeUserDialog() {
      this.selectedUser = null;
      this.selectedUrl = null;
      this.displayMenu = false;
    },
    toggleDisplayMenu(value) {
      if (this.displayMenu !== value) {
        this.displayMenu = value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.actions {
  &__config {
    border-radius: 5px;
  }
}

.appointments {
  &__date {
    &:first-child {
      display: none !important;
    }
  }

  &__appointment {
    box-shadow: 0px 3px 6px #00000029 !important;
  }
}

.date {
  color: var(--v-secondaryFont-base);
}

@include breakpoint(small) {
  .menu-open {
    padding-left: 40vw;
  }
}

@include breakpoint(medium) {
  .menu-open {
    padding-left: 10vw;
  }
}
</style>
